import React, { useEffect, useState } from "react";
import axios from "axios";
import { useResumeContext } from "../../context/resumeContext";
import { formatDate } from "../../utils/helpers";
import Modal from "../../components/common/modal";
import ResumeDetail from "../../components/pages/ResumeDetail";
import { useLocation, useNavigate } from "react-router-dom";

const ResumeList = () => {
  // Hook to get the current location object
  let location = useLocation();

  // Extracting filesArray from location state
  const filesArray = location?.state?.data;

  // Hook to navigate programmatically
  let nav = useNavigate();

  // Using context to manage languages state across components
  const { languages, setLanguages } = useResumeContext();

  // State for storing fetched resumes
  const [resumes, setResumes] = useState([]);

  // State for storing input value from the search bar
  const [value, setValue] = useState("");

  // State for storing GitHub username to display project details in a modal
  const [gitHubUserName, setGitHubUserName] = useState("");

  // State to control the visibility of the resume detail modal
  const [resumeDetailModel, setResumeDetailModel] = useState(false);

  // Base URL for accessing resume files
  let baseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  // Function to handle key press events, specifically the Enter key
  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      setLanguages(value.split(","));
    }
  };

  // useEffect hook to fetch resumes based on selected languages
  useEffect(() => {
    const fetchResumes = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/resumeSearch`,
          {
            params: {
              languages: languages.join(","),
              emailId: "null",
              gitHubUserName: "null",
            },
          }
        );
        setResumes(response.data);
      } catch (error) {
        console.error("Failed to fetch resumes:", error);
      }
    };
    if (languages.length > 0) {
      fetchResumes();
    }
  }, [languages]);

  // Function to open a new tab with the provided link
  const opneLink = (link) => window.open(link);

  // Function to handle the opening of the modal and setting GitHub username
  const handleOpenModal = (name) => {
    setResumeDetailModel(true);
    setGitHubUserName(name);
  };

  // Function to handle clicking on a resume card and opening the resume file
  const handleResumeCardClick = (resumeFile) => {
    window.open(`${baseUrl}${resumeFile}`);
  };

  // Function to render a recent added resumes
  function renderRecentlyAddedResume() {
    return (
      filesArray && (
        <div className="row">
          <div className="col-12 mb-5">
            {filesArray?.length > 0 && (
              <div className="col-12">
                <h2>Recently Added Resume:</h2>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-2 g-3 mt-2">
                  {filesArray?.map((resume, index) => (
                    <div key={index} className="col position-relative">
                      <div className="btn-blk">
                        <button
                          onClick={() =>
                            handleResumeCardClick(resume?.resumeFile)
                          }
                        >
                          View Resume
                        </button>
                        <button
                          onClick={() =>
                            handleOpenModal(resume?.gitHubUserName)
                          }
                        >
                          Project Detail
                        </button>
                      </div>
                      <div className="resume-card">
                        <span>{resume?.emailId}</span>
                        <p onClick={() => opneLink(resume?.linkedInLink)}>
                          {resume?.linkedInLink}
                        </p>
                        <p onClick={() => opneLink(resume?.gitHubLink)}>
                          {resume?.gitHubLink}
                        </p>
                        <span>{formatDate(resume?.createdAt)}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )
    );
  }

  // Function to render a resumes archive
  function renderResumeArchive() {
    return (
      resumes?.data?.length > 0 && (
        <div>
          <h2>Resumes Archive:</h2>
          <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-2 g-3 mt-2">
            {resumes?.data?.map((resume, index) => (
              <div key={index} className="col position-relative">
                <div className="btn-blk">
                  <button
                    onClick={() => handleResumeCardClick(resume?.resumeFile)}
                  >
                    View Resume
                  </button>
                  <button
                    onClick={() => handleOpenModal(resume?.gitHubUserName)}
                  >
                    Project Detail
                  </button>
                </div>
                <div className="resume-card">
                  <span>{resume?.emailId}</span>
                  <p onClick={() => opneLink(resume?.linkedInLink)}>
                    {resume?.linkedInLink}
                  </p>
                  <p onClick={() => opneLink(resume?.gitHubLink)}>
                    {resume?.gitHubLink}
                  </p>
                  <span>{formatDate(resume?.createdAt)}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )
    );
  }

  // Function to render a "No resume found!" message when no resumes are available
  function renderNotFound() {
    if (resumes?.data?.length === 0)
      return (
        <div className="notFound-txt">
          <h2 className="text-center">No resume found!</h2>
        </div>
      );
  }

  return (
    <div className="container p-5 ">
      <button onClick={() => nav("/home")} className="back-btn">
        Back to dashboard
      </button>
      <div className="search-btn-blk mb-5">
        <div className="search-input-btn-blk">
          <input
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Enter technology name comma seprated"
            className="form-input "
            onKeyPress={handleKeyPress}
          />
        </div>
        <button
          onClick={() => setLanguages(value.split(","))}
          className="search-btn"
        >
          <i class="fa-solid fa-magnifying-glass "></i>
          Search
        </button>
      </div>
      {renderRecentlyAddedResume()}
      {renderResumeArchive()}
      {renderNotFound()}
      <Modal
        open={resumeDetailModel}
        closeModalFn={() => setResumeDetailModel(false)}
        loading={false}
        saveFn={() => alert("on progress")}
        title={"Projects Detail"}
        saveFnRequired={false}
      >
        <ResumeDetail gitHubUserName={gitHubUserName} />
      </Modal>
    </div>
  );
};

export default ResumeList;
